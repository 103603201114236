<section id="cover" class="min-vh-100">
  <div id="cover-caption">
    <div class="container">
      <div class="row text-white">
        <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4">
          <img src="https://oneclout.com/wp-content/uploads/2016/05/OC-Logo-new.png" id="logo">
          <div class="px-2">
            <form ngNativeValidate (ngSubmit)="onSubmit()" class="justify-content-center">
              <div class="form-group">
                <label class="sr-only">Name</label>
                <input type="email" required [(ngModel)]="user.login" name="login" class="form-control"
                  placeholder="Email">
              </div>
              <div class="form-group">
                <label class="sr-only">Email</label>
                <input type="password" required [(ngModel)]="user.password" name="password" class="form-control"
                  placeholder="Password">
              </div>
              <button type="submit" class="btn btn-lg">Login</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
