import { Component, OnInit } from '@angular/core';
import { RecruiterDashboardComponent } from '../recruiter-dashboard/recruiter-dashboard.component';
import { HttpService } from '../http.service'
import { ActivatedRoute, Router } from '@angular/router';
declare const $: any;

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  user_access = window['_current_user'].access;
  user = window['_current_user'];
  onLogOut() {

    localStorage.removeItem('token');
    this.router.navigate(['/login']);
    this.http.postRequest('accounts/logout/', {}).subscribe((res: any) => {
      console.log(res);
    })
  }
  constructor(private http: HttpService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.user_access = window['_current_user'].access;
    $('.close-alert').on('click', function () {
      $(this).closest('.alert').hide();
    });
  }
  onRefresh(route) {
    let redirectUrl = this.router.url.split(';')[0];
    if(route === redirectUrl) {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      let currentUrl = this.router.url + '?';
      this.router.navigateByUrl(currentUrl)
        .then(() => {
          this.router.navigated = false;
          this.router.navigate([redirectUrl]);
        });
    }
    else {
      this.router.navigate([route]);
    }
  }
}

