import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service'
import helpers from '../helper'
import * as moment from 'moment';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-interviewer-dashboard',
  templateUrl: './interviewer-dashboard.component.html',
  styleUrls: ['./interviewer-dashboard.component.scss']
})
export class InterviewerDashboardComponent implements OnInit {
  jobs: any;
  candidates: any;
  interviews: any;
  todayInterviews: any;
  constructor(private http: HttpService,private router: Router) { }

  ngOnInit(): void {
    this.http.getRequest('positions/list?open=true').subscribe((res: any) => {
      console.log(res);
      this.jobs = res.length;
    });

    this.http.getRequest('interviews/list?status=Shortlisted').subscribe((res: any) => {
      console.log(res);
      this.candidates = res.length;
    });

    this.http.getRequest('interviews/list?status=Scheduled').subscribe((res: any) => {
      console.log(res);
      this.interviews = res.length;
    });

    this.http.getRequest('interviews/list?schedule_time=today').subscribe((res: any) => {
      console.log(moment().format("YYYY-MM-DD"));
      this.todayInterviews = res.length;
    });
  }
  positions(){
    this.router.navigate(['/positions/',{ param: 'open=true',}]);

  }
  shortListed(){
    this.router.navigate(['/interviews/',{ param: 'status=Shortlisted',}]);
  }
  scheduleInterview(){
    this.router.navigate(['/interviews/',{ param: 'status=Scheduled',}]);
  }
  scheduleInterviewToday(){
    this.router.navigate(['/interviews/',{ param: 'schedule_time=today',}]);
  }

}
