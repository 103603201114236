import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service'
import { ActivatedRoute, Router } from '@angular/router';
declare const $: any;
@Component({
  selector: 'app-domain-form',
  templateUrl: './domain-form.component.html',
  styleUrls: ['./domain-form.component.scss']
})
export class DomainFormComponent implements OnInit {
  submitted = false;
  path = '';
  id: any;
  delete_id:any;
  save:boolean=false;
  domain = { name: '' }
  onSubmit() {
    this.submitted = true;
    if (this.id) {
      this.http.putRequest('domains/edit/' + this.id + '/', this.domain).subscribe((res: any) => {
        this.router.navigate(['/domains/']);
      });
    }
    else {

      this.http.postRequest('domains/create/', this.domain).subscribe((res: any) => {
        this.router.navigate(['/domains/']);
      });
    }
  }
  constructor(private http: HttpService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.path = this.route.snapshot.url[0].path;
    this.id = this.route.snapshot.params.id;
    if (this.id){
      this.save=true;
      this.http.getRequest('domains/list?id=' + this.id).subscribe((res: any) => {
        this.domain = res[0];
      });
      let self=this;
      $('#domainDeleteModal').on('click', 'button.yes', function () {
        self.delete_id = self.domain['id'];
        self.http.deleteRequest(self.path + '/delete/' + self.delete_id).subscribe((res: any) => {
          self.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
            self.router.navigate([`/${self.path}`]);
          });
        }, (err) => {
          $('#alertModal').modal().find('.modal-body p').html("This " + self.path.slice(0, -1) + " is used in some other records!");
        });
      })
    }
  }

}
