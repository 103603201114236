<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<app-layout>
    <div class="main brouterLinkorder border rounded">
        <h3 *ngIf="!id" class="heading">Schedule Interview</h3>
        <div *ngIf="id">
            <h3 class="heading">Candidate Information</h3>
            <form class="read" enctype="multipart/form-data">
                <div class="row">
                    <div class="form-group">
                        <label for="position">Position</label>
                        <span class="field-value">{{candidate.position['name']}}</span>

                    </div>
                    <div class="form-group">
                        <label for="position">Interview</label>
                        <span class="field-value">{{interview.interview}}</span>

                    </div>
                    <div class="form-group">
                        <label for="name">Full Name</label>
                        <span class="field-value">{{candidate.name}}</span>

                    </div>
                    <div class="form-group">
                        <label for="experience_in_years">Experience</label>
                        <span class="field-value">
              <span *ngIf="candidate.experience_in_years != '0'">{{candidate.experience_in_years}} years </span>
                        <span *ngIf="candidate.experience_in_months != '0'">{{candidate.experience_in_months}} months</span>
                        <span *ngIf="candidate.experience_in_months == '0' && candidate.experience_in_years == '0' ">fresh</span>
                        </span>

                    </div>
                    <div class="form-group">
                        <label for="email">Email</label>
                        <span class="field-value">{{candidate.email}}</span>

                    </div>
                    <div class="form-group">
                        <label for="current_salary">Current Salary</label>
                        <span class="field-value">{{candidate.current_salary}}</span>

                    </div>
                    <div class="form-group">
                        <label for="phone_no">Phone</label>
                        <span class="field-value">{{candidate.phone_no}}</span>

                    </div>
                    <div class="form-group">
                        <label for="expected_salary">Expected Salary</label>
                        <span class="field-value">{{candidate.expected_salary}}</span>

                    </div>

                    <div class="form-group">
                        <label for="address">Address</label>
                        <span class="field-value">{{candidate.address}}</span>

                    </div>
                    <div class="form-group">
                        <label for="city">City</label>
                        <span class="field-value">{{candidate.city}}</span>

                    </div>


                    <div class="form-group">
                        <label for="resume">Resume: </label>
                        <span class="field-value"> <a *ngIf="candidate.resume" target="blank" class="fa fa-file fa-lg"
                href="{{candidate.resume}}"></a>
            </span>

                    </div>
                </div>
            </form>
        </div>

        <form ngNativeValidate class="{{edit?'edit':'read'}}" (ngSubmit)="onSubmit()" #positionForm="ngForm" method="post" enctype="multipart/form-data">
            <div class="row">
                <div *ngIf="user_access > 1 && edit" class="form-group">
                    <label for="position">Interview</label>
                    <select [disabled]="user_access < 2" class="form-control" id="interview" required [(ngModel)]="interview.interview" name="interview" #position="ngModel">
            <option value="First">First</option>
            <option value="Second">Second</option>
            <option value="Final">Final</option>
            <option value="Telephonic">Telephonic</option>

          </select>
                </div>
                <div *ngIf="user_access > 1 && edit" class="form-group">
                    <label for="candidate">Candidate</label>
                    <select class="form-control" [disabled]="user_access < 2 || existing_candidate" id="candidate" required [(ngModel)]="interview.candidate['id']" name="candidate" #position="ngModel">
            <option *ngFor="let user of candidates" [value]="user.id">{{user.name}}</option>
          </select>
                </div>

                <div *ngIf="user_access > 1" class="form-group">
                    <label for="interviewer">Interviewer</label>
                    <!-- <ng-multiselect-dropdown *ngIf="edit" [placeholder]="'Interviewer'" [settings]="dropdownSettings" [data]="interviewers" required [(ngModel)]="interview.interviewer['id']" name="interviewer" #position="ngModel">
                    </ng-multiselect-dropdown> -->
                    <select *ngIf="edit" [disabled]="user_access < 2" class="form-control" id="interviewer" required [(ngModel)]="interview.interviewer['id']" name="interviewer" #position="ngModel">
            <option *ngFor="let user of interviewers" [value]="user.id">{{user.username}}</option>
          </select>
                    <span *ngIf="!edit" class="field-value">{{interview.interviewer['username']}}</span>

                </div>

                <div *ngIf="user_access > 1" class="form-group">
                    <label for="scheduler">Scheduler</label>
                    <select *ngIf="edit" [disabled]="true" class="form-control" id="scheduler" required [(ngModel)]="interview.scheduler['id']" name="scheduler" #position="ngModel">
            <option *ngFor="let user of recruiters" [value]="user.id">{{user.username}}</option>
          </select>
                    <span *ngIf="!edit" class="field-value">{{interview.scheduler['username']}}</span>

                </div>


                <div *ngIf="user_access > 1" class="form-group">
                    <label for="schedule_time">Date and Time</label>
                    <input placeholder="DD/MM/YYYY HH:MM" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" *ngIf="edit" [disabled]="user_access < 2" class="form-control" id="schedule_time" required [(ngModel)]="interview.schedule_time" name="schedule_time" #name="ngModel">
                    <span *ngIf="!edit" class="field-value">{{interview.schedule_time}}</span>
                    <owl-date-time #dt1></owl-date-time>

                </div>
                <div *ngIf="id" class="form-group">
                    <label for="score">Interview Score <a *ngIf="!save" data-toggle="modal" data-target="#scoreCardModal" id="scoreCard" (click)="getScore()">Score card</a>
          </label>
                    <input *ngIf="edit" type="number" class="form-control score" id="score" [required]="user_access == 1" [(ngModel)]="interview.score" name="score" #name="ngModel">
                    <span *ngIf="!edit" class="field-value">{{interview.score}}</span>

                </div>

                <div class="form-group">
                    <label for="position">Status</label>
                    <select *ngIf="edit" class="form-control" id="status" required [(ngModel)]="interview.status" name="status" #position="ngModel">
            <option value="Pending">Pending</option>
            <option value="Scheduled">Scheduled</option>
            <option value="Selected">Selected</option>
            <option value="Rejected">Rejected</option>
            <option value="Not Appeared">Not Appeared</option>
            <option value="Shortlisted">Shortlisted</option>
            <option value="Top Priority">Top Priority</option>
            <option value="Future Pool">Future Pool</option>
            <option value="Offer Refused">Offer Refused</option>
          </select>
                    <span *ngIf="!edit" class="field-value">{{interview.status}}</span>

                </div>
                <div *ngIf="id" class="form-group form-group-full">
                    <label>Remarks</label>
                    <div *ngIf="user_access < 2 && edit" class="form-group my-remarks form-group-full">
                        <textarea class="form-control" id="text" [(ngModel)]="my_remarks.text" name="text" #name="ngModel"></textarea>
                    </div>
                    <nz-list nzItemLayout="horizontal">
                        <nz-list-item *ngFor="let item of remarks">
                            <nz-list-item-meta nzAvatar="https://banner2.cleanpng.com/20180521/ocp/kisspng-computer-icons-user-profile-avatar-french-people-5b0365e4f1ce65.9760504415269493489905.jpg">

                                <nz-list-item-meta-title>
                                    <strong>{{ item.user.username }}</strong> ({{ item.user.user_type }})
                                </nz-list-item-meta-title>
                                <nz-list-item-meta-description>
                                    {{ item.text }}
                                </nz-list-item-meta-description>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                </div>

                <!-- <div *ngIf="user_access > 1" class="w-100">
                    <div *ngIf="recruiterRemarks" class="form-group">
                        <label for="interviewer">Interviewer</label>
                        <select *ngIf="edit" [disabled]="user_access < 2" class="form-control" id="interviewer" required [(ngModel)]="interview.interviewer['id']" name="interviewer" #position="ngModel">
                <option *ngFor="let user of interviewers" [value]="user.id">{{user.username}}</option>
              </select>
                        <span *ngIf="!edit" class="field-value">{{interview.interviewer['username']}}</span>

                    </div>
                    <div *ngIf="recruiterRemarks" class="form-group">
                        <label>Remarks</label>
                        <textarea class="form-control" id="text" [(ngModel)]="interview.remarks_by_interviewer" name="text" #name="ngModel"></textarea>
                    </div>
                </div> -->
            </div>


            <button type="submit" *ngIf="save" class="btn btn-light border border-dark float-right mr-2">Save</button>
            <button type="submit" *ngIf="edit && !save" class="btn btn-light border border-dark float-right mr-2">Update</button>
            <a *ngIf="user_access > 1 && !save" data-toggle="modal" data-target="#interviewDeleteModal" class=" btn btn-light border border-dark float-right mr-2">Delete</a>
            <button routerLink="{{'/'+path}}" class=" btn btn-light border border-dark float-right mr-2">Back</button>
            <!-- <button *ngIf="user_access > 1" (click)='toggleRemarks()' type="button" class=" btn btn-light border border-dark float-right mr-2">Add Remarks</button> -->
        </form>
    </div>
    <div class="modal fade delete-modal" id="interviewDeleteModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel">Confirmation</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
            <span class="sr-only">Close</span>
          </button>
                </div>
                <div class="modal-body">
                    <p>Do you really want to delete this interview?</p>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary-outline yes" data-dismiss="modal">Yes</button>
                </div>
            </div>
        </div>
    </div>
    <!--  Score card Modal-->
    <div class="modal fade delete-modal" id="scoreCardModal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel">Interview Score Card</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
            <span class="sr-only">Close</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="row" style="padding: 10px">
                        <div class="col-4 font-weight-bold">Criteria</div>
                        <div class="col-4 font-weight-bold">Score</div>
                    </div>
                    <div *ngFor="let criteria of scoreCard">
                        <div class="row" style="padding: 17px; border-top: 1px solid lightgray;">
                            <div class="col-4">
                                <div style="font-weight: bold;">{{criteria.name}}</div>
                            </div>
                            <div class="col-2 font-weight-bold">Excellent</div>
                            <div class="col-2 font-weight-bold">Good</div>
                            <div class="col-2 font-weight-bold">Fair</div>
                            <div class="col-2 font-weight-bold">Poor</div>
                        </div>
                        <div class="row" *ngFor="let point of criteria.points" style="padding-bottom: 5px">
                            <div class="col-4">{{point.description}}</div>
                            <div class="col-8" *ngFor="let score of point.score">
                                <div *ngIf="point.score.length > 0" class="row">
                                    <div class="col-3">
                                        <input [disabled]="scoreCardDisable" id="{{score.id}}" [checked]="score.score === 'excellent'" class="form-check-input" type="radio" name="{{point.id}}" value="excellent">
                                    </div>
                                    <div class="col-3">
                                        <input [disabled]="scoreCardDisable" id="{{score.id}}" [checked]="score.score === 'good'" class="form-check-input" type="radio" name="{{point.id}}" value="good">
                                    </div>
                                    <div class="col-3">
                                        <input [disabled]="scoreCardDisable" id="{{score.id}}" [checked]="score.score === 'fair'" class="form-check-input" type="radio" name="{{point.id}}" value="fair">
                                    </div>
                                    <div class="col-3">
                                        <input [disabled]="scoreCardDisable" id="{{score.id}}" [checked]="score.score === 'bad'" class="form-check-input" type="radio" name="{{point.id}}" value="bad">
                                    </div>
                                </div>
                            </div>
                            <div class="col-8" *ngIf="point.score.length < 1">
                                <div class="row">
                                    <div class="col-3">
                                        <input [disabled]="scoreCardDisable" class="submit_radio" type="radio" name="{{point.id}}" value="excellent">
                                    </div>
                                    <div class="col-3">
                                        <input [disabled]="scoreCardDisable" class="submit_radio" type="radio" name="{{point.id}}" value="good">
                                    </div>
                                    <div class="col-3">
                                        <input [disabled]="scoreCardDisable" class="submit_radio" type="radio" name="{{point.id}}" value="fair">
                                    </div>
                                    <div class="col-3">
                                        <input [disabled]="scoreCardDisable" class="submit_radio" type="radio" name="{{point.id}}" value="bad">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding: 10px;">
                        <div class="col-4 font-weight-bold">Interview Score</div>
                        <div class="col-2"><input [disabled]="scoreCardDisable" id="interview_score" type="number" required></div>
                    </div>
                </div>
                <!--          <table class="table table-borderless">-->
                <!--            <thead>-->
                <!--            <tr>-->
                <!--              <th scope="col">Criteria</th>-->
                <!--              <th scope="col">Score</th>-->
                <!--            </tr>-->
                <!--            </thead>-->
                <!--            <tbody>-->
                <!--            <tr *ngFor="let criteria of scoreCard">-->
                <!--              <td>-->
                <!--                <table class="table table-bordered">-->
                <!--                  <thead>-->
                <!--                  <tr>-->
                <!--                    <th scope="col">{{criteria.name}}</th>-->
                <!--                    <th scope="col">Excellent</th>-->
                <!--                    <th scope="col">Good</th>-->
                <!--                    <th scope="col">Fair</th>-->
                <!--                    <th scope="col">Bad</th>-->
                <!--                  </tr>-->
                <!--                  </thead>-->
                <!--                  <tbody>-->
                <!--                  <tr *ngFor="let point of criteria.points">-->
                <!--                    <td>-->
                <!--                      <table class="table table-bordered">-->
                <!--                        <thead>-->
                <!--                        <tr>-->
                <!--                          <th >{{point.description}}</th>-->
                <!--                        </tr>-->
                <!--                        </thead>-->
                <!--                      </table>-->
                <!--                    </td>-->
                <!--                  </tr>-->
                <!--                  </tbody>-->

                <!--                </table>-->
                <!--              </td>-->

                <!--            </tr>-->
                <!--            </tbody>-->
                <!--          </table>-->
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary-outline yes" (click)="postScore()" [hidden]="scoreCardDisable">Save</button>
                </div>
            </div>


        </div>
    </div>
</app-layout>