<app-layout>
  <div class="main border rounded">
    <h3 class="heading">{{id?"":"New"}} Candidate</h3>
    <form ngNativeValidate class="{{edit?'edit':'read'}}" (ngSubmit)="onSubmit(false)" #positionForm="ngForm"
      method="post" enctype="multipart/form-data">
      <div class="row">

        <div class="form-group">
          <label for="name">Full Name</label>
          <input *ngIf="edit" type="text" class="form-control" id="name" required [(ngModel)]="candidate.name"
            name="name" #name="ngModel">
          <span class="field-value" *ngIf="!edit">{{candidate.name}}</span>

        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input *ngIf="edit" type="email" class="form-control" id="email" [(ngModel)]="candidate.email" name="email"
            #name="ngModel">
          <span class="field-value" *ngIf="!edit">{{candidate.email}}</span>

        </div>
        <div class="form-group">
          <label for="phone_no">Phone</label>
          <input *ngIf="edit" type="text" class="form-control" id="phone_no" [(ngModel)]="candidate.phone_no"
            name="phone_no" #name="ngModel">
          <span class="field-value" *ngIf="!edit">{{candidate.phone_no}}</span>

        </div>
        <div class="salary-fields">
          <div class="form-group">
            <label for="experience_in_years">Experience Years</label>
            <input *ngIf="edit" type="number" required class="form-control" id="experience_in_years"
                   [(ngModel)]="candidate.experience_in_years" name="experience_in_years" #name="ngModel" step="1">
            <span class="field-value" *ngIf="!edit">{{candidate.experience_in_years}}</span>
          </div>
          <div class="form-group">
            <label for="experience_in_years">Months</label>
            <input *ngIf="edit" type="number" required class="form-control" id="experience_in_months"
                   [(ngModel)]="candidate.experience_in_months" name="experience_in_months" #name="ngModel">
            <span class="field-value" *ngIf="!edit">{{candidate.experience_in_months}}</span>
          </div>
        </div>
        <div class="salary-fields">
          <div class="form-group">
            <label for="current_salary">Current Salary</label>
            <input *ngIf="edit" type="number" class="form-control" id="current_salary" required
              [(ngModel)]="candidate.current_salary" name="current_salary" #name="ngModel">

            <span class="field-value" *ngIf="!edit">{{candidate.current_salary}}</span>
          </div>
          <div class="form-group">
            <label for="expected_salary">Expected Salary</label>
            <input *ngIf="edit" type="number" class="form-control" id="expected_salary"
              [(ngModel)]="candidate.expected_salary" name="expected_salary" #name="ngModel">
            <span class="field-value" *ngIf="!edit">{{candidate.expected_salary}}</span>

          </div>
        </div>


        <div class="form-group">
          <label for="address">Permanent Address</label>
          <input *ngIf="edit" type="text" class="form-control" id="address" [(ngModel)]="candidate.address"
            name="address" #name="ngModel">
          <span class="field-value" *ngIf="!edit">{{candidate.address}}</span>

        </div>
        <div class="form-group">
          <label for="city">Current City</label>
          <input *ngIf="edit" type="text" class="form-control" id="city"  [(ngModel)]="candidate.city"
            name="city" #name="ngModel">
          <span class="field-value" *ngIf="!edit">{{candidate.city}}</span>

        </div>
        <div class="form-group">
          <label for="position">Position</label>
          <select *ngIf="edit" class="form-control" id="position" required [(ngModel)]="candidate.position['id']"
            name="position" #position="ngModel">
            <option *ngFor="let position of positions" [value]="position.id">{{position.name}}</option>
          </select>
          <span class="field-value" *ngIf="!edit">{{candidate.position['name']}}</span>

        </div>

        <div class="form-group">
          <label for="resume">Resume: </label>
          <span class="field-value"> <a *ngIf="candidate.resume" target="blank" class="fa fa-file fa-lg"
              href="{{candidate.resume}}"></a>
          </span>
          <input *ngIf="edit" [(ngModel)]="candidate.resume" #name="ngModel" (change)="onChange($event)" type="file"
            class="form-control" id="resume" name="resume">
        </div>

        <div *ngIf="remarks" class="form-group form-group-full">
          <label>Remarks</label>
          <div *ngIf="edit" class="form-group my-remarks form-group-full">
            <textarea class="form-control" id="text" [(ngModel)]="my_remarks.text" name="text"
              #name="ngModel"></textarea>
          </div>
          <nz-list nzItemLayout="horizontal">
            <nz-list-item *ngFor="let item of remarks">
              <nz-list-item-meta
                nzAvatar="https://banner2.cleanpng.com/20180521/ocp/kisspng-computer-icons-user-profile-avatar-french-people-5b0365e4f1ce65.9760504415269493489905.jpg">

                <nz-list-item-meta-title>
                  <strong>{{ item.user.username }}</strong> ({{ item.user.user_type }})
                </nz-list-item-meta-title>
                <nz-list-item-meta-description>
                  {{ item.text }}
                </nz-list-item-meta-description>
              </nz-list-item-meta>
            </nz-list-item>
          </nz-list>
        </div>

      </div>

      <button type="button" [disabled]="!positionForm.form.valid" (click)="onSchedule($event)"
        class=" btn btn-light border border-dark float-right mr-2">Schedule
        interview</button>
      <button type="submit" *ngIf="save" class="btn btn-light border border-dark float-right mr-2">Save</button>
      <button type="submit" *ngIf="edit && !save" class="btn btn-light border border-dark float-right mr-2">Update</button>
      <a *ngIf="edit && !save" data-toggle="modal" data-target="#candidateDeleteModal" class=" btn btn-light border border-dark float-right mr-2">Delete</a>
      <button routerLink="{{'/'+path}}" class=" btn btn-light border border-dark float-right mr-2">Back</button>
    </form>
  </div>
  <div class="modal fade delete-modal" id="candidateDeleteModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel">Confirmation</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
            <span class="sr-only">Close</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Do you really want to delete this candidate?</p>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary-outline yes" data-dismiss="modal">Yes</button>
        </div>
      </div>
    </div>
  </div>
</app-layout>
