<app-layout>
  <div class="main border rounded">
    <h3 class="heading">{{id?"":"New"}} Recruiter</h3>
    <form ngNativeValidate class="{{edit?'edit':'read'}}" (ngSubmit)="onSubmit()" #positionForm="ngForm" method="post"
      enctype="multipart/form-data">
      <div class="row">

        <div class="form-group">
          <label for="username">UserName</label>
          <input *ngIf="edit" type="text" class="form-control" id="username" required [(ngModel)]="recruiter.username"
            name="username" #name="ngModel">
          <span class="field-value" *ngIf="!edit">{{recruiter.username}}</span>

        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input *ngIf="edit" type="email" class="form-control" id="email" required [(ngModel)]="recruiter.email"
            name="email" #name="ngModel">
          <span class="field-value" *ngIf="!edit">{{recruiter.email}}</span>

        </div>
        <div class="form-group">
          <label for="phone_no">Phone</label>
          <input *ngIf="edit" type="text" class="form-control" id="phone_no" required [(ngModel)]="recruiter.phone_no"
            name="phone_no" #name="ngModel">
          <span class="field-value" *ngIf="!edit">{{recruiter.phone_no}}</span>

        </div>
        <div *ngIf="edit" class="form-group">
          <label for="password">Password</label>
          <input type="password" class="form-control" id="password" required [(ngModel)]="recruiter.password"
            name="password" #name="ngModel">
        </div>
      </div>


      <button *ngIf="edit && !save" type="submit" class="btn btn-light border border-dark float-right mr-2">Update</button>
      <button *ngIf="save" type="submit" class="btn btn-light border border-dark float-right mr-2">Save</button>
      <a *ngIf="edit" data-toggle="modal" data-target="#recruiterDeleteModal" class=" btn btn-light border border-dark float-right mr-2">Delete</a>
      <button routerLink="{{'/'+path}}" class=" btn btn-light border border-dark float-right mr-2">Back</button>

    </form>
  </div>
  <div class="modal fade delete-modal" id="recruiterDeleteModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel">Confirmation</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
            <span class="sr-only">Close</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Do you really want to delete this recruiter?</p>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary-outline yes" data-dismiss="modal">Yes</button>
        </div>
      </div>
    </div>
  </div>
</app-layout>
