<app-layout>
    <div class="main border rounded">
        <h3 class="heading">Profile</h3>
        <form ngNativeValidate (ngSubmit)="onSubmit()" #positionForm="ngForm" method="post"
            enctype="multipart/form-data">
            <div class="row">
                <div class="form-group">
                    <label for="username">UserName</label>
                    <input type="text" class="form-control" id="username" required [(ngModel)]="profile.username"
                        name="username" #name="ngModel">
                </div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="email" class="form-control" id="email" required [(ngModel)]="profile.email"
                        name="email" #name="ngModel">
                </div>
                <div class="form-group">
                    <label for="phone_no">Phone</label>
                    <input type="text" class="form-control" id="phone_no" required [(ngModel)]="profile.phone_no"
                        name="phone_no" #name="ngModel">
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" class="form-control" id="password" required [(ngModel)]="profile.password"
                        name="password" #name="ngModel">
                </div>
            </div>


            <button type="submit" class="btn btn-light border border-dark float-right mr-2">Save</button>
            <button routerLink="{{'/'}}" class=" btn btn-light border border-dark float-right mr-2">Back</button>

        </form>
    </div>
</app-layout>