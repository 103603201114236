import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service'
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
declare const $: any;

@Component({
  selector: 'app-candidate-form',
  templateUrl: './candidate-form.component.html',
  styleUrls: ['./candidate-form.component.scss']
})
export class CandidateFormComponent implements OnInit {
  submitted = false;
  path = '';
  edit;
  save;
  positions: any;
  id: any;
  file: any;
  delete_id: any;
  remarks = [];
  my_remarks = { user_pk: window["_current_user"].id, text: '', entity: 'candidate', entity_id: '' };
  candidate = { name: '', email: '', phone_no: '', current_salary: '', expected_salary: '', experience_in_years: '',experience_in_months: '', address: '', resume: '', position: {}, city: '' }

  onSchedule(event) {
    this.onSubmit(true)
  }
  onChange(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

  onSubmit(schedule_interview) {
    let { name, email, phone_no, city, current_salary, expected_salary, experience_in_years,experience_in_months, address, position } = this.candidate;
    const formData = new FormData();
    if (this.file)
      formData.append('resume', this.file);
    formData.append('name', name);
    if(email != ''){formData.append('email', email);}
    formData.append('phone_no', phone_no);
    formData.append('current_salary', current_salary);
    formData.append('expected_salary', expected_salary);
    formData.append('experience_in_years', experience_in_years);
    formData.append('experience_in_months', experience_in_months);
    formData.append('address', address);
    formData.append('city', city);
    formData.append('position', position['id']);


    this.submitted = true;
    if (this.id) {
      this.http.putRequest('candidates/edit/' + this.id + '/', formData).subscribe((res: any) => {
        this.saveRemark(res);
        if (schedule_interview)
          this.router.navigate(['/interviews/create/' + this.id]);
        else
          this.router.navigate(['/candidates/']);
      });
    }
    else {

      this.http.postRequest('candidates/create/', formData).subscribe((res: any) => {
        this.saveRemark(res);
        if (schedule_interview)
          this.router.navigate(['/interviews/create/' + res.id]);
        else
          this.router.navigate(['/candidates/']);

      },
        err => {
          this.toastr.error(err.error.email)
        });
    }

  }

  saveRemark(candidate) {
    if (this.my_remarks.text == '')
      return;
    if (this.my_remarks.entity_id) {
      this.http.putRequest('remarks/edit/' + this.my_remarks['id'] + '/', this.my_remarks).subscribe((res: any) => {
        
      });
    }
    else {
      this.my_remarks.entity_id = candidate.id;
      this.http.postRequest('remarks/create/', this.my_remarks).subscribe((res: any) => {
        
      });
    }
  }

  constructor(private http: HttpService, private route: ActivatedRoute, private router: Router,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    var self= this
    this.http.getRequest('positions/list').subscribe((res) => {
      
      this.positions = res;
    });
    this.path = this.route.snapshot.url[0].path;
    this.edit = ['edit', 'create'].includes(this.route.snapshot.url[1].path)
    this.save = ['create'].includes(this.route.snapshot.url[1].path)
    this.id = this.route.snapshot.params.id;
    if (this.id){
      this.http.getRequest(`remarks/list?entity=candidate&entity_id=${this.id}`).subscribe((res: any) => {
      
        this.remarks = this.remarks.concat(res);
        let arr = res.filter(r => r.user.id == window['_current_user'].id)
        if (arr.length)
          this.my_remarks = arr[0]
        if (window['_current_user'].user_type == 'recruiter')
          this.remarks = res.filter(r => r.user.user_type != 'admin')
      });
    }
    if (this.id)
      this.http.getRequest('candidates/list/?id=' + this.id).subscribe((res: any) => {
        
        this.candidate = res.results[0];
        for (var i in res.interview_set) {
          this.http.getRequest(`remarks/list?entity=interview&entity_id=${res.interview_set[i]}`).subscribe((res: any) => {
            this.remarks = this.remarks.concat(res);
          });
        }
      });

    $('#candidateDeleteModal').on('click', 'button.yes', function () {
      self.delete_id = self.candidate['id'];
      self.http.deleteRequest(self.path + '/delete/' + self.delete_id).subscribe((res: any) => {
        
        self.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
          self.router.navigate([`/${self.path}`]);
        });
      }, (err) => {
        console.log(err)
        $('#alertModal').modal().find('.modal-body p').html("This " + self.path.slice(0, -1) + " is used in some other records!");
      });
    })

  }

}
