import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service'
import { ActivatedRoute, Router } from '@angular/router';
declare const $: any;

@Component({
  selector: 'app-recruiter-form',
  templateUrl: './recruiter-form.component.html',
  styleUrls: ['./recruiter-form.component.scss']
})
export class RecruiterFormComponent implements OnInit {
  submitted = false;
  path = '';
  edit;
  save;
  positions: any;
  delete_id: any;
  id: any;
  recruiter = { username: '', email: '', phone_no: '', user_type: 'recruiter', password: '' }
  onSubmit() {
    this.submitted = true;
    console.log(this.recruiter)
    if (this.id) {
      this.http.putRequest('users/edit/' + this.id + '/', this.recruiter).subscribe((res: any) => {
        console.log(res);
        this.router.navigate(['/recruiters/']);
      });
    }
    else {

      this.http.postRequest('users/create/', this.recruiter).subscribe((res: any) => {
        console.log(res);
        this.router.navigate(['/recruiters/']);
      });
    }
  }
  constructor(private http: HttpService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    let self = this
    this.path = this.route.snapshot.url[0].path;
    this.edit = ['edit'].includes(this.route.snapshot.url[1].path)
    this.save = ['create'].includes(this.route.snapshot.url[1].path)
    this.id = this.route.snapshot.params.id;
    if (this.id)
      this.http.getRequest('users/' + this.id).subscribe((res: any) => {
        console.log(res);
        this.recruiter = res;
      });
    $('#recruiterDeleteModal').on('click', 'button.yes', function () {
      self.delete_id = self.recruiter['id'];
      self.http.deleteRequest(self.path + '/delete/' + self.delete_id).subscribe((res: any) => {
        console.log(res);
        self.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
          self.router.navigate([`/${self.path}`]);
        });
      }, (err) => {
        console.log(err)
        $('#alertModal').modal().find('.modal-body p').html("This " + self.path.slice(0, -1) + " is used in some other records!");
      });
    })
  }


}

