import { Component, OnInit } from '@angular/core';
import {Chart}  from 'chart.js';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-graph-view',
  templateUrl: './graph-view.component.html',
  styleUrls: ['./graph-view.component.scss']
})
export class GraphViewComponent implements OnInit {
  canvas: any;
  ctx: any;
  canvas1: any;
  ctx1: any;
  constructor(private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show()
    this.canvas = document.getElementById('myChart');
    this.ctx = this.canvas.getContext('2d');
    this.canvas1 = document.getElementById('myChart1');
    this.ctx1 = this.canvas1.getContext('2d');
    setTimeout(() => {
      this.spinner.hide()
      let myChart = new Chart(this.ctx, {
        type: 'bar',
        data: {
          labels: ['Angular', 'PHP', 'React', 'Phyton', 'SQA', 'BD'],
          datasets: [{
            label: 'Interviews',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
          }
          ]
        },
        options: {
          scales: {
            yAxes: [{
              beginAtZero: true,
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Days'
              }
            }],
            xAxes: [{
              beginAtZero: true,
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Domains'
              }
            }]
          },
          responsive: true,
          title: {
              display: true,
              text: 'Weekly Interviews'
                  },
        }
      });
      let myChart1 = new Chart(this.ctx1, {
        type: 'bar',
        data: {
          labels: ['Asim Aziz', 'Awais Altaf', 'Haleema Saeed', 'Naseer Ahmad', 'Shahzaib Ali', 'Sheraz Saeedy'],
          datasets: [{
            label: 'Interviewer',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
          }
          ]
        },
        options: {
          
          scales: {
            yAxes: [{
              beginAtZero: true,
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Days'
              }
            }],
            xAxes: [{

              beginAtZero: true,
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Interviwers'
              }
            }]
          },
          responsive: true,
          title: {
              display: true,
              text: 'Weekly Interviews By Interviwers'
                  },
        }
      });
    }, 1000);
  }

}
