import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../http.service'

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  Object = Object;
  labels:any;
  title:any;
  path:any;
  id:any;
  item = { id: 11,
  name: 'Ali',
  email:"test",
  test:"Interview",
  test1:"4",
  test2:"test",
  test3:"test",
  test4:"test",
  test5:"test" };

  constructor(private route: ActivatedRoute,private http:HttpService) { }

  ngOnInit(): void {
    this.labels = this.route.snapshot.data.labels || [];
    this.title = this.route.snapshot.data.title || false;
    this.path = this.route.snapshot.url[0].path;
    this.id = this.route.snapshot.params.id;
    this.http.getRequest(this.path+'/'+this.id).subscribe((res:any) => {
        console.log(res);
        this.item = res;
      });
  }

}
