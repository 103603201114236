<h1 class="display-4 d-none d-sm-block">
</h1>
<div class="alert alert-warning fade collapse" role="alert" id="myAlert">
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">×</span>
    <span class="sr-only">Close</span>
  </button>
</div>
<div class="row mb-3">
    <div class="col-xl-3 col-sm-6 py-2">
        <div class="card text-white h-100">
            <div class="card-body" (click)="positions()">
                <div class="rotate">
                    <i class="fa fa-briefcase fa-4x"></i>
                </div>
                <h6 class="text-uppercase">Job Openings</h6>
                <h1 class="display-4">{{jobs}}</h1>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-sm-6 py-2">
        <div class="card text-white  h-100">
            <div class="card-body" (click)="shortListed()">
                <div class="rotate">
                    <i class="fa fa-user-friends fa-4x"></i>
                </div>
                <h6 class="text-uppercase">Shortlisted Candidates</h6>
                <h1 class="display-4">{{candidates}}</h1>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-sm-6 py-2">
        <div class="card text-white h-100">
            <div class="card-body" (click)="scheduleInterview()">
                <div class="rotate">
                    <i class="fa fa-calendar fa-4x"></i>
                </div>
                <h6 class="text-uppercase">Scheduled Interviews</h6>
                <h1 class="display-4">{{interviews}}</h1>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-sm-6 py-2">
        <div class="card text-white h-100">
            <div class="card-body" (click)="scheduleInterviewToday()">
                <div class="rotate">
                    <i class="fa fa-calendar fa-4x"></i>
                </div>
                <h6 class="text-uppercase">Today's Interviews</h6>
                <h1 class="display-4">{{todayInterviews}}</h1>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-sm-6 py-2">
        <div class="card text-white h-100">
            <div class="card-body" (click)="priorityView()">
                <div class="rotate">
                    <i class="fa fa-calendar fa-4x"></i>
                </div>
                <h6 class="text-uppercase">Top Priorities</h6>
                <h1 class="display-4">{{priorityview}}</h1>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-sm-6 py-2">
        <div class="card text-white h-100">
            <div class="card-body" (click)="poolView()">
                <div class="rotate">
                    <i class="fa fa-calendar fa-4x"></i>
                </div>
                <h6 class="text-uppercase">Future Pool</h6>
                <h1 class="display-4">{{poolview}}</h1>
            </div>
        </div>
    </div>
</div>