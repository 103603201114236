<app-layout>
    <div class="main border rounded">
        <h3 class="heading">{{id?"":"New"}} Domain</h3>
        <form ngNativeValidate (ngSubmit)="onSubmit()" #positionForm="ngForm" method="post" enctype="multipart/form-data">
            <div class="row">
                <div class="form-group">
                    <label for="name">Name</label>
                    <input type="text" class="form-control" id="name" required [(ngModel)]="domain.name" name="name" #name="ngModel">
                </div>
            </div>

            <button type="submit" class="btn btn-light border border-dark float-right">Save</button>
            <a *ngIf="save" data-toggle="modal" data-target="#domainDeleteModal" class=" btn btn-light border border-dark float-right mr-2">Delete</a>
            <button routerLink="{{'/'+path}}" class=" btn btn-light border border-dark float-right mr-2">Back</button>
        </form>
    </div>
    <div class="modal fade delete-modal" id="domainDeleteModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel">Confirmation</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
            <span class="sr-only">Close</span>
          </button>
                </div>
                <div class="modal-body">
                    <p>Do you really want to delete this domain?</p>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary-outline yes" data-dismiss="modal">Yes</button>
                </div>
            </div>
        </div>
    </div>
</app-layout>