import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LayoutComponent } from './layout/layout.component';
import { RecruiterDashboardComponent } from './recruiter-dashboard/recruiter-dashboard.component';
import { HomeComponent } from './home/home.component';
import { InterviewerDashboardComponent } from './interviewer-dashboard/interviewer-dashboard.component';
import { RecruitersComponent } from './recruiters/recruiters.component';
import { ListComponent } from './list/list.component';
import { DetailComponent } from './detail/detail.component';
import { PositionFormComponent } from './position-form/position-form.component';
import { CandidateFormComponent } from './candidate-form/candidate-form.component';
import { InterviewFormComponent } from './interview-form/interview-form.component';
import { InterviewerFormComponent } from './interviewer-form/interviewer-form.component';
import { DomainFormComponent } from './domain-form/domain-form.component';
import { RecruiterFormComponent } from './recruiter-form/recruiter-form.component';
import { LoginComponent } from './login/login.component';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProfileComponent } from './profile/profile.component';
import {ToastrModule} from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { OwlDateTimeModule, OwlNativeDateTimeModule,OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgToggleModule } from 'ng-toggle-button';
import { GraphViewComponent } from './graph-view/graph-view.component';


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    RecruiterDashboardComponent,
    LoginComponent,
    HomeComponent,
    InterviewerDashboardComponent,
    RecruitersComponent,
    ListComponent,
    DetailComponent,
    PositionFormComponent,
    CandidateFormComponent,
    InterviewFormComponent,
    InterviewerFormComponent,
    DomainFormComponent,
    RecruiterFormComponent,
    ProfileComponent,
    GraphViewComponent,
  ],
  imports: [
    NgToggleModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NzListModule,
    NzDropDownModule,
    NzAvatarModule,
    NzIconModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot() ,// ToastrModule added
    NgxSpinnerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
