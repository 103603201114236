<app-layout>
    <div class="main border rounded">
        <h3 class="heading">{{id?"":"New"}} Position</h3>
        <form ngNativeValidate class="{{edit?'edit':'read'}}" (ngSubmit)="onSubmit()" #positionForm="ngForm">
            <div class="row">
                <div class="form-group">
                    <label class="field-label" for="name">Title</label>
                    <input *ngIf="edit" [disabled]="user_access < 2" type="text" class="form-control" id="name" required [(ngModel)]="position.name" name="name" #name="ngModel">
                    <span class="field-value" *ngIf="!edit">{{position.name}}</span>
                </div>
                <div class="form-group">
                    <label for="name">Experience Required(years) </label>
                    <input *ngIf="edit" [disabled]="user_access < 2" type="number" class="form-control" id="required_experience" required [(ngModel)]="position.required_experience" name="required_experience" #name="ngModel">
                    <span class="field-value" *ngIf="!edit">{{position.required_experience}}</span>
                </div>
                <div class="form-group form-group-full">
                    <label for="details">Details</label>
                    <textarea *ngIf="edit" [disabled]="user_access < 2" type="text" class="form-control" id="details" required [(ngModel)]="position.details" name="details" #name="ngModel"></textarea>
                    <p class="field-value" *ngIf="!edit">{{position.details}}</p>
                </div>
                <div *ngIf="!edit" class="form-group">
                    <label for="name">Salary(from) </label>
                    <input *ngIf="edit" [disabled]="user_access < 2" type="number" class="form-control" id="required_experience" required [(ngModel)]="position.salary_range_from" name="required_experience" #name="ngModel">
                    <span class="field-value" *ngIf="!edit">{{position.salary_range_from}}</span>
                </div>
                <div *ngIf="!edit" class="form-group">
                    <label for="name">Salary(to) </label>
                    <input *ngIf="edit" [disabled]="user_access < 2" type="number" class="form-control" id="required_experience" required [(ngModel)]="position.salary_range_to" name="required_experience" #name="ngModel">
                    <span class="field-value" *ngIf="!edit">{{position.salary_range_to}}</span>
                </div>
                <div *ngIf="edit" class="form-group">
                    <label for="name">Salary range</label>
                    <div class="salary-inputs">
                        <input [disabled]="user_access < 2" [disabled]="user_access < 2" type="number" class="form-control salary" id="salary_range_from" required [(ngModel)]="position.salary_range_from" name="salary_range_from" #name="ngModel"> -

                        <input [disabled]="user_access < 2" [disabled]="user_access < 2" type="number" class="form-control salary" id="salary_range_to" required [(ngModel)]="position.salary_range_to" name="salary_range_to" #name="ngModel">

                    </div>
                </div>


                <div class="form-group">
                    <label for="domain">Domain</label>
                    <select *ngIf="edit" [disabled]="user_access < 2" class="form-control" id="domain" required [(ngModel)]="position.domain['id']" name="domain" #domain="ngModel">
            <option *ngFor="let domain of domains" [value]="domain.id">{{domain.name}}</option>
          </select>
                    <span class="field-value" *ngIf="!edit">{{position.domain['name']}}</span>

                </div>
                <div class="form-group">
                    <label for="area">Area</label>
                    <select [disabled]="user_access < 2" class="form-control" *ngIf="edit" id="area" required [(ngModel)]="position.area" name="area" #domain="ngModel">
            <option value="Frontend">Frontend</option>
            <option value="Backend">Backend</option>
            <option value="Both">Both</option>
          </select>
                    <span class="field-value" *ngIf="!edit">{{position.area}}</span>

                </div>

                <div class="form-group">
                    <label for="date">Expiry</label>
                    <input placeholder="DD/MM/YYYY HH:MM" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" *ngIf="edit" [disabled]="user_access < 2" class="form-control" id="date" required [(ngModel)]="position.date" name="date" #name="ngModel">
                    <span class="field-value" *ngIf="!edit">{{position.date}}</span>
                    <owl-date-time #dt1></owl-date-time>
                </div>
                <div class="form-group form-group-full">
                    <label class="status-label" for="open">Status</label>
                    <input [disabled]="user_access < 2 || !edit" type="checkbox" class="form-control" id="open" [(ngModel)]="position.open" name="open" #name="ngModel">
                </div>

            </div>
            <button *ngIf="edit" [disabled]="user_access < 2" type="submit" class="btn btn-light border border-dark float-right">Save</button>
            <a *ngIf="save" data-toggle="modal" data-target="#positionDeleteModal" class=" btn btn-light border border-dark float-right mr-2">Delete</a>
            <button routerLink="{{'/'+path}}" class=" btn btn-light border border-dark float-right mr-2">Back</button>

        </form>
    </div>
    <div class="modal fade delete-modal" id="positionDeleteModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel">Confirmation</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
            <span class="sr-only">Close</span>
          </button>
                </div>
                <div class="modal-body">
                    <p>Do you really want to delete this position?</p>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary-outline yes" data-dismiss="modal">Yes</button>
                </div>
            </div>
        </div>
    </div>
</app-layout>