import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { RecruitersComponent } from './recruiters/recruiters.component';
import { ListComponent } from './list/list.component';
import { DetailComponent } from './detail/detail.component';
import { PositionFormComponent } from './position-form/position-form.component';
import { CandidateFormComponent } from './candidate-form/candidate-form.component';
import { InterviewFormComponent } from './interview-form/interview-form.component';
import { InterviewerFormComponent } from './interviewer-form/interviewer-form.component';
import { DomainFormComponent } from './domain-form/domain-form.component';
import { RecruiterFormComponent } from './recruiter-form/recruiter-form.component';
import { ProfileComponent } from './profile/profile.component';
import { GraphViewComponent } from './graph-view/graph-view.component';

import { AuthGuard } from './auth.guard';

const ACCESS = { 'interviewer': 1, 'recruiter': 2, 'admin': 3 }
if (localStorage["user"]) {
  window['_current_user'] = JSON.parse(localStorage["user"])
  const { user_type } = window['_current_user']
  if (['recruiter', 'interviewer', 'admin'].includes(user_type))
    window['_current_user']['access'] = ACCESS[user_type]
}



const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'recruiters', component: ListComponent },
  { path: 'interviewers', component: ListComponent },
  { path: 'interviews', component: ListComponent },
  { path: 'interviews', component: ListComponent },
  { path: 'candidates', component: ListComponent },
  { path: 'domains', component: ListComponent },
  { path: 'positions', component: ListComponent },
  { path: 'graphs', component:  GraphViewComponent},

  { path: 'recruiters/create', component: RecruiterFormComponent },
  { path: 'domains/create', component: DomainFormComponent },
  { path: 'interviews/create', component: InterviewFormComponent },
  { path: 'interviews/create/:candidate', component: InterviewFormComponent },
  { path: 'candidates/create', component: CandidateFormComponent },
  { path: 'positions/create', component: PositionFormComponent },
  { path: 'interviewers/create', component: InterviewerFormComponent },

  //   {path: 'recruiters/:id',component:DetailComponent,data:{labels:['Name','Email','Phone'],title:"test",columns: [{ data: 'username' },{ data: 'email' },{ data: 'phone_no' },]}},
  //   {path: 'interviewers/:id',component:DetailComponent,data:{labels:['Name','Email','Phone'],title:"name",columns: [{ data: 'username' },{ data: 'email' },{ data: 'phone_no' },]}},
  //   {path: 'interviews/:id',component:DetailComponent,data:{labels:['Date','Remarks','Remarks','Score','Status','Candidate','Scheduler','Interviewer'],title:"test"}},
  //   {path: 'candidates/:id',component:DetailComponent,data:{labels:['id','Name','Email','Phone','Current Salary','Expected Salary','Experience','Address','Resume','Position'],title:"test"}},
  //   {path: 'domains/:id',component:DetailComponent,data:{labels:['id','Name','Email','Position','Experience','Test','Test','Test','Test','Test'],title:"test"}},
  //   {path: 'positions/:id',component:DetailComponent,data:{labels:['id','Title','Description','Status','Salary','Experience','Test'],title:"test"}},/

  { path: 'recruiters/:id', component: RecruiterFormComponent },
  { path: 'domains/:id', component: DomainFormComponent },
  { path: 'interviews/:id', component: InterviewFormComponent },
  { path: 'candidates/:id', component: CandidateFormComponent },
  { path: 'positions/:id', component: PositionFormComponent },
  { path: 'interviewers/:id', component: InterviewerFormComponent },

  { path: 'recruiters/edit/:id', component: RecruiterFormComponent },
  { path: 'domains/edit/:id', component: DomainFormComponent },
  { path: 'interviews/edit/:id', component: InterviewFormComponent },
  { path: 'candidates/edit/:id', component: CandidateFormComponent },
  { path: 'positions/edit/:id', component: PositionFormComponent },
  { path: 'interviewers/edit/:id', component: InterviewerFormComponent },

  { path: 'profile', component: ProfileComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
