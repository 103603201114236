<nav class="navbar fixed-top navbar-expand-md navbar-light">
    <div class="flex-row d-flex">
        <button type="button" class="navbar-toggler mr-2 " data-toggle="offcanvas" title="Toggle responsive left sidebar">
      <span class="navbar-toggler-icon"></span>
    </button>
        <a class="navbar-brand" href="#">
            <img src="https://oneclout.com/wp-content/uploads/2016/05/OC-Logo-new.png" alt="">
        </a>
    </div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
    <span class="navbar-toggler-icon"></span>
  </button>
    <div nz-button nz-dropdown [nzDropdownMenu]="menu" class="profile-dropdown">
        <span>{{user.username}}</span><i class="fa fa-user fa-lg ml-2"></i></div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
            <a class="nav-link" routerLink="/profile">Profile</a>
            <a class="nav-link" (click)="onLogOut()">Logout</a>
        </ul>
    </nz-dropdown-menu>
    <!-- <div class="navbar-collapse collapse" id="collapsingNavbar">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">


      </li>
    </ul>
  </div> -->
</nav>
<div class="container-fluid" id="main">
    <div class="row row-offcanvas row-offcanvas-left">
        <div class="col-md-3 col-lg-2 sidebar-offcanvas bg-light pl-0" id="sidebar" role="navigation">
            <ul class="nav flex-column sticky-top pl-0 pt-5 mt-3">
                <li class="nav-item"><a class="nav-link" routerLinkActive="active-route" routerLink="/home" (click)="onRefresh('/home')"><i
              class="fas fa-chart-line"></i> Dashboard</a></li>
                <li *ngIf="user_access > 1" class="nav-item"><a class="nav-link" routerLinkActive="active-route" routerLink="/domains" (click)="onRefresh('/domains')"><i class="fas fa-industry"></i> Domains</a></li>
                <li class="nav-item"><a class="nav-link" routerLinkActive="active-route" routerLink="/positions" (click)="onRefresh('/positions')"><i
              class="fas fa-briefcase"></i> Positions</a>
                </li>
                <li *ngIf="user_access > 1" class="nav-item"><a class="nav-link" routerLinkActive="active-route" routerLink="/candidates" (click)="onRefresh('/candidates')"><i class="fas fa-user-friends"></i>
            Candidates</a></li>
                <li *ngIf="user_access > 2" class="nav-item"><a class="nav-link" routerLinkActive="active-route" routerLink="/recruiters" (click)="onRefresh('/recruiters')"><i class="fas fa-user-plus"></i> Recruiters</a>
                </li>
                <li *ngIf="user_access > 1" class="nav-item"><a class="nav-link" routerLinkActive="active-route" routerLink="/interviewers" (click)="onRefresh('/interviewers')"><i class="fas fa-user-plus"></i>
            Interviewers</a></li>
                <li class="nav-item"><a class="nav-link" routerLinkActive="active-route" routerLink="/interviews" (click)="onRefresh('/interviews')"><i
              class="fas fa-calendar"></i> Interviews</a>
                </li>
                <li class="nav-item"><a class="nav-link" routerLinkActive="active-route" routerLink="/graphs" (click)="onRefresh('/graphs')"><i class="fas fa-chart-line"></i> Graphs</a>
                </li>

            </ul>
        </div>
        <!--/col-->
        <div class="col pt-3 root-container">

            <ng-content></ng-content>
        </div>


        <!--/main col-->
    </div>

</div>
<!--/.container-->


<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel"></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
          <span class="sr-only">Close</span>
        </button>
            </div>
            <div class="modal-body">
                <p>This is a dashboard layout for Bootstrap 4. This is an example of the Modal component which you can use to show content. Any content can be placed inside the modal and it can use the Bootstrap grid classes.</p>
                <p>
                    <a href="https://www.codeply.com/go/KrUO8QpyXP" target="_ext">Grab the code at Codeply</a>
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary-outline" data-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>