<app-layout>
<div class="detail-view">
      <h3><a routerLink="{{'/'+path}}">{{path}}/</a>{{item.name}}</h3>
  <button routerLink="{{'/'+path+'/edit/'+id}}" class="btn btn-primary" >Edit</button>
  <div *ngFor="let key of Object.keys(item);index as i" [ngClass]="{'hidden': i==0}" class="field">
    <span class="label">{{labels[i]}}</span>
    <span class="description">{{item[key]}}</span>
  </div>
</div>
</app-layout>
