import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recruiters',
  templateUrl: './recruiters.component.html',
  styleUrls: ['./recruiters.component.scss']
})
export class RecruitersComponent implements OnInit {
  recruiters=[
  { id: 11, name: 'Dr Nice',email:"test@email.com" },
  { id: 12, name: 'Narco',email:"test@email.com" },
  { id: 13, name: 'Bombasto',email:"test@email.com" },
  { id: 14, name: 'Celeritas' ,email:"test@email.com"},
  { id: 15, name: 'Magneta' ,email:"test@email.com"},
  { id: 16, name: 'RubberMan',email:"test@email.com" },
  { id: 17, name: 'Dynama',email:"test@email.com" },
  { id: 18, name: 'Dr IQ',email:"test@email.com" },
  { id: 19, name: 'Magma',email:"test@email.com" },
  { id: 20, name: 'Tornado',email:"test@email.com" }
];
  constructor() { }

  ngOnInit(): void {
  }

}
