import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    // 'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

function setHeaders() {
  if (localStorage.getItem('token') != null)
    httpOptions.headers =
      httpOptions.headers.set('Authorization', 'Token ' + localStorage.getItem('token'));
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  apiUrl = location.hostname == "localhost" ? "http://localhost:8000/api/" : "https://recruitmentapi.oneclout.com/api/";
  redirectUrl = '/';
  constructor(private httpClient: HttpClient) {
    console.log(this.apiUrl);
  }

  handleError(error: HttpErrorResponse) {
    console.log(error)
    return throwError(error);
  }

  public getRequest(url: any): Observable<Object> {
    setHeaders();
    return this.httpClient.get(this.apiUrl + url, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  postRequest(url: any, data: Object): Observable<Object> {
    setHeaders();
    return this.httpClient.post(this.apiUrl + url, data, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  putRequest(url: any, data: Object): Observable<Object> {
    setHeaders();
    return this.httpClient.patch(this.apiUrl + url, data, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  deleteRequest(url: any): Observable<Object> {
    setHeaders();
    return this.httpClient.delete(this.apiUrl + url, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

}
