import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../http.service'
import helpers from '../helper'
import * as moment from 'moment';
import {ActivatedRoute, Router} from "@angular/router";
@Component({
  selector: 'app-recruiter-dashboard',
  templateUrl: './recruiter-dashboard.component.html',
  styleUrls: ['./recruiter-dashboard.component.scss']
})
export class RecruiterDashboardComponent implements OnInit {
  jobs: any;
  candidates: any;
  interviews: any;
  todayInterviews: any;
  priorityview:any;
  poolview:any;
  constructor(private http: HttpService,private route: ActivatedRoute,private router: Router) { }

  ngOnInit(): void {
    this.http.getRequest('positions/list?open=true').subscribe((res: any) => {
      this.jobs = res.results.length;
    });

    this.http.getRequest('interviews/list?status=Shortlisted').subscribe((res: any) => {
      this.candidates = res.results.length;
    });

    this.http.getRequest('interviews/list?status=Scheduled').subscribe((res: any) => {
      this.interviews = res.results.length;
    });

    this.http.getRequest('interviews/list?schedule_time=today').subscribe((res: any) => {
      this.todayInterviews = res.results.length;
    });
    this.http.getRequest('interviews/list?status=Top Priority').subscribe((res: any) => {
      this.priorityview = res.results.length;
    });
    this.http.getRequest('interviews/list?status=Future Pool').subscribe((res: any) => {
      this.poolview = res.results.length;
    });
  }
  positions(){
    this.router.navigate(['/positions/',{ param: 'open=true',}]);

  }
  shortListed(){
      this.router.navigate(['/interviews/',{ param: 'status=Shortlisted',}]);
  }
  scheduleInterview(){
    this.router.navigate(['/interviews/',{ param: 'status=Scheduled',}]);
  }
  scheduleInterviewToday(){
    this.router.navigate(['/interviews/',{ param: 'schedule_time=today',}]);
  }
  priorityView(){
    this.router.navigate(['/interviews/',{ param: 'status=Top Priority',}]);
  }
  poolView(){
    this.router.navigate(['/interviews/',{ param: 'status=Future Pool',}]);
  }

}

