import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service'
import { ActivatedRoute, Router } from '@angular/router';
import {ToastrService} from "ngx-toastr";
declare const $: any;
@Component({
  selector: 'app-interviewer-form',
  templateUrl: './interviewer-form.component.html',
  styleUrls: ['./interviewer-form.component.scss']
})
export class InterviewerFormComponent implements OnInit {
  submitted = false;
  path = '';
  edit;
  save;
  positions: any;
  id: any;
  delete_id: any;
  interviewer = { username: '', email: '', phone_no: '', user_type: 'interviewer', password: '' }
  user_access = window['_current_user'].access;


  onSubmit() {
    this.submitted = true;
    if (this.id) {
      this.http.putRequest('users/edit/' + this.id + '/', this.interviewer).subscribe((res: any) => {
        this.router.navigate(['/interviewers/']);
      });
    }
    else {

      this.http.postRequest('users/create/', this.interviewer).subscribe((res: any) => {
        this.router.navigate(['/interviewers/']);
      },
        err => {
          this.toastr.error(err.error.email)
        });
    }
  }
  constructor(private http: HttpService, private route: ActivatedRoute, private router: Router,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    let self = this
    this.path = this.route.snapshot.url[0].path;
    this.edit = ['edit', 'create'].includes(this.route.snapshot.url[1].path)
    this.save = ['create'].includes(this.route.snapshot.url[1].path)
    this.id = this.route.snapshot.params.id;
    if (this.id)
      this.http.getRequest('users/' + this.id).subscribe((res: any) => {
        this.interviewer = res;
      });
    $('#interviewerDeleteModal').on('click', 'button.yes', function () {
      self.delete_id = self.interviewer['id'];
      self.http.deleteRequest(self.path + '/delete/' + self.delete_id).subscribe((res: any) => {
        self.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
          self.router.navigate([`/${self.path}`]);
        });
      }, (err) => {
        $('#alertModal').modal().find('.modal-body p').html("This " + self.path.slice(0, -1) + " is used in some other records!");
      });
    })

  }

}
