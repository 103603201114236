import * as moment from 'moment';

export default class Helpers {
  static renderDate(date, time) {
    if (!time)
      return moment(date).format("DD-MM-YYYY")
    else
      return moment(date).format("DD/MM/YYYY hh:mm A")

  }

  static formatDate(date) {
    return moment(date).format("DD-MM-YYYYTHH:mm:ssZ")
  }
  static formatDate2(date) {
    return moment(date).format("DD/MM/YYYY hh:mm A")
  }
}
