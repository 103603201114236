<h2>Recruiters</h2>
<table  class="table">
  <tbody>
  <tr *ngFor="let r of recruiters">
    <td>{{r.name}}</td>
    <td>{{r.email}}</td>
  </tr>
  </tbody>
</table>

