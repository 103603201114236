import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service'
import { ActivatedRoute, Router } from '@angular/router';
import helpers from '../helper'
import * as moment from 'moment';
import {DateTimeAdapter} from "ng-pick-datetime";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
declare const $: any;
import { HttpClient } from '@angular/common/http';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-interview-form',
  templateUrl: './interview-form.component.html',
  styleUrls: ['./interview-form.component.scss']
})
export class InterviewFormComponent implements OnInit {
  submitted = false;
  path = '';
  edit;
  save;
  existing_candidate;
  recruiters: any;
  interviewers;
  id: any;
  recruiterRemarks:boolean=false;
  options: {ex:'excellent',good:'good',fair:'fair',bad:'bad'}
  scoreCard: any;
  scoreAdded: any;
  scoreLength: any;
  candidates: any;
  delete_id: any;
  dateFinderChecker:any;
  selection_item=[];
  dropdownSettings:IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'username',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
    allowSearchFilter: true
  };
  scoreCardDisable:any;
  interview = { candidate: {}, scheduler: {}, schedule_time: '', interviewer: {}, remarks_by_recruiter: '', remarks_by_interviewer: '', score: '', status: 'Scheduled', interview: 'First' }
  user_access = window['_current_user'].access;
  remarks = [];
  my_remarks = { user_pk: window["_current_user"].id, text: '', entity: 'interview', entity_id: '' };
  candidate = { name: '', email: '', phone_no: '', current_salary: '', expected_salary: '', experience_in_years: '',experience_in_months:'', address: '', resume: '', position: '', city: '' }

  onSubmit() {
    this.interview.interviewer = this.interview.interviewer['id'];
    this.interview.scheduler = this.interview.scheduler['id'];
    this.interview.candidate = this.interview.candidate['id'];
    if (!this.interview.score)
      delete this.interview.score
    this.submitted = true;
    if(this.dateFinderChecker ==this.interview.schedule_time){

    }else{
      this.interview.schedule_time = helpers.formatDate2(this.interview.schedule_time)
    }
    if (this.id) {
      this.http.putRequest('interviews/edit/' + this.id + '/', this.interview).subscribe((res: any) => {
        this.saveRemark(res);
        this.router.navigate(['/interviews/']);
      });
    }
    else {
      this.http.postRequest('interviews/create/', this.interview).subscribe((res: any) => {
        this.router.navigate(['/interviews/']);

      });
    }

  }

  saveRemark(interview) {
    if (this.my_remarks.text == '')
      return;
    if (this.my_remarks.entity_id) {
      this.http.putRequest('remarks/edit/' + this.my_remarks['id'] + '/', this.my_remarks).subscribe((res: any) => {
        
      });
    }
    else {
      this.my_remarks.entity_id = interview.id;
      this.http.postRequest('remarks/create/', this.my_remarks).subscribe((res: any) => {
        
      });
    }
  }

  constructor(private spinner: NgxSpinnerService,private http: HttpService,private req:HttpClient,private route: ActivatedRoute,private toastr: ToastrService, private router: Router
  ,dateTimeAdapter: DateTimeAdapter<any>) {
    dateTimeAdapter.setLocale('en-IN')
  }

  ngOnInit(): void {
    setTimeout(() => {
      $('#schedule_time').val(this.interview.schedule_time)
    }, 2000);
    this.scoreCardDisable = this.user_access > 1
    this.spinner.show();
    let self = this;
    this.user_access = window['_current_user'].access;
    this.http.getRequest('candidates/list?all').subscribe((res) => {
      this.candidates = res;
    });
    this.http.getRequest('users/list/?user_type=interviewer').subscribe((res) => {
      
      this.interviewers = res;
    });
    this.http.getRequest('users/list/?user_type=recruiter').subscribe((res) => {
      
      this.recruiters = res;
    });
    this.path = this.route.snapshot.url[0].path;
    this.edit = ['edit', 'create'].includes(this.route.snapshot.url[1].path);
    this.save = ['create'].includes(this.route.snapshot.url[1].path);
    this.id = this.route.snapshot.params.id;
    let candidate_id = this.route.snapshot.params.candidate;
    this.interview.candidate = { id: candidate_id };
    this.interview.scheduler = { id: window['_current_user'].id };
    if (candidate_id) {
      this.existing_candidate = true
    }

    if (this.id) {
      this.http.getRequest('interviews/list/?id=' + this.id).subscribe((res: any) => {
        
        this.interview = res.results[0];
        $('#schedule_time').val('values')
        this.dateFinderChecker=this.interview.schedule_time;
        this.http.getRequest('candidates/list/?id=' + this.interview.candidate['id']).subscribe((res: any) => {
          this.candidate = res.results[0];
          for (var i in res.results[0].interview_set) {
            this.http.getRequest(`remarks/list?entity=interview&entity_id=${res.results[0].interview_set[i]}`).subscribe((res: any) => {
              this.remarks = this.remarks.concat(res);
              let arr = this.remarks.filter(r => r.user.id == window['_current_user'].id)
              if (arr.length)
                this.my_remarks = arr[0]
            });
          }
        });
        this.http.getRequest(`remarks/list?entity=candidate&entity_id=${this.interview.candidate['id']}`).subscribe((res: any) => {
          
          this.remarks = this.remarks.concat(res);

          if (window['_current_user'].access != 3)
            this.remarks = res.filter(r => r.user.user_type != 'admin')
        });

      });




    }
    $('#interviewDeleteModal').on('click', 'button.yes', function () {
      self.delete_id = self.interview['id'];
      self.http.deleteRequest(self.path + '/delete/' + self.delete_id).subscribe((res: any) => {
        
        self.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
          self.router.navigate([`/${self.path}`]);
        });
      }, (err) => {
        $('#alertModal').modal().find('.modal-body p').html("This " + self.path.slice(0, -1) + " is used in some other records!");
      });
    })
     this.spinner.hide()

  }
  getScore(){
    this.spinner.show()
    this.http.getRequest(`criteria/list/?candidate=${this.interview.candidate['id']}&interview=${this.id}`).subscribe((res: any) => {
      
      this.scoreCard = res
      this.scoreAdded = res[0].points[0].score.length > 0
    });
    this.http.getRequest(`point/list`).subscribe((res: any) => {
      this.scoreLength = res.length
    });
    this.http.getRequest('interviews/list/?id=' + this.id).subscribe((res: any) => {
      
      this.interview = res[0];
      $('#interview_score').val(this.interview.score)
    });
    this.spinner.hide()
  }

  postScore(){
    let v= $('input[type=radio].submit_radio:checked');
    let score_number= $('#interview_score').val();
    let score = []
    let candidate_id =this.interview.candidate['id']
    let interview_id = this.id
    $(v).each(function(i){
      let json = {
           candidate: candidate_id,
           interview:interview_id,
           point: parseInt($(this).attr('name')),
           score: $(this).val()
      }
      score.push(json)
    });
    if(score_number != '' && !this.scoreAdded) {
     if(v.length !== this.scoreLength){
        this.toastr.error('Please select all points to score.')
      }else {
       $('#scoreCardModal').modal('hide');
       this.http.postRequest('scorecard/create', score).subscribe((res: any) => {
         this.toastr.success(res.detail)
         this.http.putRequest('interviews/edit/' + this.id + '/', {"score": score_number}).subscribe((res: any) => {
           
           $('#score').val(res.score)
         });
       });
     }
    }
    else if(score_number === ''){
      this.toastr.error('Please add the interview score')
    }
    else if(score_number != '' && this.scoreAdded){
      let select_radios= $('input[type=radio].form-check-input:checked');
      let score_list = []
      $(select_radios).each(function(i){
        let json = {
          id:parseInt($(this).attr('id')),
          candidate: candidate_id,
          interview:interview_id,
          point: parseInt($(this).attr('name')),
          score: $(this).val()
        }
        score_list.push(json)
      });
      $('#scoreCardModal').modal('hide');
      this.http.putRequest('scorecard/update', score_list).subscribe((res: any) => {
        // this.toastr.success(res.detail)
        this.http.putRequest('interviews/edit/' + this.id + '/', {"score": score_number}).subscribe((res: any) => {
          
          $('#score').val(res.score)
          $('#interview_score').val(res.score)
          this.saveRemark(res);
          // this.router.navigate(['/interviews/']);
        });
      });

    }

  }
  // public toggleRemarks(){
  //   this.recruiterRemarks=!this.recruiterRemarks
  // }
}
