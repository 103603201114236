import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service'
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import helpers from '../helper'
declare const $: any;

@Component({
  selector: 'app-position-form',
  templateUrl: './position-form.component.html',
  styleUrls: ['./position-form.component.scss']
})
export class PositionFormComponent implements OnInit {
  submitted = false;
  path = '';
  save:boolean=false;
  edit;
  domains: any;
  lastdate:any;
  id: any;
  delete_id:any;
  user_access = window['_current_user'].access;
  position = {
    name: '', required_experience: '', details: '', salary_range_from: '',
    salary_range_to: '', domain: {}, open: false, date: '', area: ''
  }
  onSubmit() {
    if(this.lastdate==this.position.date){
      this.position.date=this.position.date;
    }else{
      this.position.date = helpers.formatDate2(this.position.date)
    }
    this.position.domain = this.position.domain['id']
    this.submitted = true;
    if (this.id) {
      this.http.putRequest('positions/edit/' + this.id + '/', this.position).subscribe((res: any) => {
        this.router.navigate(['/positions/']);
      });
    }
    else {
      this.http.postRequest('positions/create/', this.position).subscribe((res: any) => {
        this.router.navigate(['/positions/']);
      });
    }


  }

  constructor(private http: HttpService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.user_access = window['_current_user'].access;
    this.http.getRequest('domains/list').subscribe((res) => {
      this.domains = res;
    });
    this.path = this.route.snapshot.url[0].path;
    this.edit = ['edit', 'create'].includes(this.route.snapshot.url[1].path)
    this.id = this.route.snapshot.params.id;
    if(this.id){
      this.http.getRequest('positions/list/?id=' + this.id).subscribe((res: any) => {
        this.save=true;
        this.position = res[0];
        this.lastdate=this.position.date
        setTimeout(() => {
          $('#date').val(this.position.date)
        }, 2000);
      });
    }
      let self=this;
      $('#positionDeleteModal').on('click', 'button.yes', function () {
        self.delete_id = self.position['id'];
        self.http.deleteRequest(self.path + '/delete/' + self.delete_id).subscribe((res: any) => {
          self.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
            self.router.navigate([`/${self.path}`]);
          });
        }, (err) => {
          $('#alertModal').modal().find('.modal-body p').html("This " + self.path.slice(0, -1) + " is used in some other records!");
        });
      })
  }

}
