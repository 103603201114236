import { Component, OnInit } from '@angular/core';
import { EmailValidator } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../http.service'
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  user = { login: '', password: '' }
  onSubmit() {
    this.http.postRequest('accounts/login/', this.user).subscribe((res: any) => {
      localStorage.setItem('token', res.token);

      this.http.getRequest('accounts/profile/').subscribe((res: any) => {
        console.log(res);
        localStorage.setItem('user', JSON.stringify(res));
        window["_current_user"] = res;

        const ACCESS = { 'interviewer': 1, 'recruiter': 2, 'admin': 3 }
        window['_current_user'] = res
        const { user_type } = res
        if (['recruiter', 'interviewer', 'admin'].includes(user_type))
          window['_current_user']['access'] = ACCESS[user_type]
        this.router.navigate([this.http.redirectUrl]);
      })


    },
    err => {
      console.log('oops', err.error.detail)
      this.toastr.error(err.error.detail)
    }
  );
  }

  constructor(private http: HttpService, private route: ActivatedRoute, private router: Router,
              private toastr: ToastrService) { }


  ngOnInit(): void {
  }

}
