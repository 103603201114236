import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service'
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  user_access = window['_current_user'] && window['_current_user'].access;
  profile = { username: '', email: '', phone_no: '', user_type: window['_current_user'].user_type, password: '' }

  onSubmit() {
    console.log(this.profile)
    this.http.putRequest('users/edit/' + this.profile['id'] + '/', this.profile).subscribe((res: any) => {
      console.log(res);
      this.router.navigate(['/']);
    });
  }
  constructor(private http: HttpService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.http.getRequest('users/' + window['_current_user'].id).subscribe((res: any) => {
      console.log(res);
      this.profile = res;
    });
  }

}
