<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<app-layout>
    <div class="main border rounded">
        <h3 class="heading">Graphs</h3>
        <div class="row">
            <div class="col-md-12 d-flex justify-content-flex-end">
                <ng-toggle [value]="false" [color]="{
                  unchecked: '#939da2',
                  checked: '#f62d51'
                }" [labels]="{
                    unchecked: 'Weekly',
                    checked: 'Monthly'
                  }" [width]=80>
                </ng-toggle>
            </div>
            <div class="col-md-6">
                <canvas id="myChart" class="w-100" height="300px"></canvas>
            </div>
            <div class="col-md-6">
                <canvas id="myChart1" class="w-100" height="300px"></canvas>
            </div>
        </div>
    </div>
</app-layout>