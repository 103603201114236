import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../http.service'
import helpers from '../helper'
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import { nextTick } from 'process';
declare const $: any;

const RENDER = {
  className: 'last',
  title: 'Action', "width": "100px",
  "render": function (data, type, row, meta) {
    return `<a class="fa fa-pen edit mr-2 ${!['interviews'].some(substring => location.href.includes(substring)) && window['_current_user'].access < 2 ? 'hidden' : 'hidden'}" /> <a class="fa fa-eye view mr-2 ${row.non_scheduled ? 'hidden' : 'hidden'}" /><a data-toggle="modal" data-target="#deleteModal" class="fa fa-trash delete ${window['_current_user'].access < 2 || row.non_scheduled ? 'hidden' : ''}" />`;
  }
}

const RENDER_DATE = function (data, type, row, meta) {

  return data
}
const RENDER_DATE_TIME = function (data, type, row, meta) {
  if (row.non_scheduled)
    return `<button class="rounded schedule-btn">Schedule</button>`
  else
    return data
}
const DATA = {
  'recruiters': { title: "Recruiters", filters: 'user_type=recruiter', columns: [{ title: 'Username', data: 'username' }, { title: 'Email', data: 'email' }, { title: 'Phone', data: 'phone_no' }] },
  'interviewers': { title: "Interviewers", filters: 'user_type=interviewer', columns: [{ title: 'Username', data: 'username' }, { title: 'Email', data: 'email' }, { title: 'Phone', data: 'phone_no' }] },
  'interviews': {
    title: "Interviews", columns: [
      { title: 'Name', data: 'candidate', render: (data, type, row, meta) => { return '<div title="' + data.name + '">' + data.name + '</div>'; } },
      { title: 'Email', data: 'candidate',width:'200px', render: (data, type, row, meta) => { return '<div title="' + data.email + '">' + data.email + '</div>';  } },
      { title: 'Position', data: 'candidate', render: (data, type, row, meta) => { if(data.position){return data.position.name}else{return ''} } },
      { title: 'Interview', data: 'interview' },
      { title: 'Status', data: 'status' },
      { title: 'Date',width:'200px', data: 'schedule_time', render: RENDER_DATE_TIME },
      { title: 'Interviewer', data: 'interviewer', render: (data, type, row, meta) => { return data.username } },
      { title: 'Actions', data: 'id', render: (data, type, row, meta) => { 
        return `<td class="text-center">
                <button class=" non-background" value="${data}">
                  <i class="fas fa-edit"></i>
                </button>
                </td>`;
       } }
    ]
  },
  'candidates': {
    title: "Candidates", columns: [{ title: 'Name', data: 'name' }, { title: 'Email', data: 'email' }, { title: 'Phone', data: 'phone_no' }, { title: 'Experience(years)', data: 'experience_in_years' }, { title: 'Address', data: 'address' },
    { title: 'Position', data: 'position', render: (data, type, row, meta) => { if(data){return data.name}else{return ''} } },
    { title: 'Actions', data: 'id', render: (data, type, row, meta) => { 
      return `<td class="text-center">
              <button class=" non-background" value="${data}">
                <i class="fas fa-edit"></i>
              </button>
              </td>`;
     } }
    ]
  },
  'domains': { title: "Domains", columns: [{ title: 'Name', data: 'name' }] },
  'positions': { title: "Positions", columns: [{ title: 'Title', data: 'name' }, { title: 'Details', data: 'details' }, { title: 'Domain', data: 'domain', render: (data) => { return data.name } }, { title: 'Expiry', data: 'date', render: RENDER_DATE }, { title: 'Status', data: 'open', render: (data) => { return data ? 'Open' : 'Closed' } },] },
}
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListComponent implements OnInit {
  Object = Object;
  list = [];
  listFilter = [];
  search:any;
  candidatesData:any=[];
  columns: any;
  path: any;
  title: any;
  pageCountData:number=0;
  filters: any;
  delete_id;
  dateFilter:boolean=false;
  user_access = window['_current_user'].access;
  paginationSet:any;
  paginationCount:any;
dateRange:any;
  constructor(private route: ActivatedRoute, private http: HttpService, private router: Router,private spinner: NgxSpinnerService) { }
  initializeTable(size=0) {
    return $('#list-view').DataTable({
      destroy: true,
      data: this.list,
      searching: false,
      columns: this.columns,
      iDisplayLength: size == 0 ? 10 : size,
      language: {
        'paginate': {
          'previous': '<span><a class="paginate_button current" aria-controls="list-view" data-dt-idx="1" tabindex="0">&lt;&lt;</a></span>',
          'next': '<span><a class="paginate_button current" aria-controls="list-view" data-dt-idx="1" tabindex="0">&gt;&gt;</a></span>'
        }
      }
    });
  }
  initializeTable2() {
    let self = this;
    return $('#list-view').DataTable({
      destroy: true,
      data: this.list,
      columns: this.columns,
      iDisplayLength: 10,
      searching: false,
      order: [[ 5, "desc" ]],
      columnDefs: [
        { type: 'date-eu', targets: 5 }    ],
        language: {
          'paginate': {
            'previous': '<span><a class="paginate_button current" aria-controls="list-view" data-dt-idx="1" tabindex="0">&lt;&lt;</a></span>',
            'next': '<span><a class="paginate_button current" aria-controls="list-view" data-dt-idx="1" tabindex="0">&gt;&gt;</a></span>'
          }
      }
    });
  }
  registerEvents(table) {
    $.fn.dataTable.moment( 'DD/MM/YYYY hh:mm A"' );
    let self = this;
    $("button").click(function() {
      var fired_button = $(this).val();
      if(fired_button){
        self.router.navigate([`/${self.path}/edit/${fired_button}`]);
      }
    });
    $('#list-view').on('click', '.fa.edit,.odd td:not(:last-child),.even td:not(:last-child)', function () {
      let row = table.row($(this).closest('tr')[0]).data();
      if(row){
        if (row.non_scheduled)
        self.router.navigate([`/${self.path}/create/${row.id}`]);
      else
        self.router.navigate([`/${self.path}/edit/${row.id}`]);
      }
    })
    $('#list-view').on('click', '.fa.view', function () {
      let row = table.row($(this).closest('tr')[0]).data();
      self.router.navigate([`/${self.path}/${row.id}`]);
    })

    $('#list-view').on('click', '.fa.delete', function () {
      let row = table.row($(this).closest('tr')[0]).data();
      self.delete_id = row.id;
    })
    $("select[name=list-view_length]").on('change', function () {
      var conceptName = $("select[name=list-view_length]").find(":selected").text();
      self.pageSize(conceptName)
    })
  }
  ngOnInit(): void {
    this.spinner.show()
    this.user_access = window['_current_user'].access;
    this.path = this.route.snapshot.routeConfig.path;
    this.title = DATA[this.path].title || [];
    this.columns = [...DATA[this.path].columns];
    if(Object.keys(this.route.snapshot.params).length == 0) {
      this.filters = DATA[this.path].filters;
    }else {
        this.filters = this.route.snapshot.params.param;
    }
    let table: any;
    let self = this;
    this.http.getRequest(this.path + '/list?'+ this.filters).subscribe((res: any) => {
      if(res.results){
        if (res.results.length){
          this.list = res.results;
          this.candidatesData=res;
          this.countPaging(res.total_pages);
        }
      }
      else if (res.length){
        this.list = res;
        this.candidatesData=res;
      }
      else{
        this.list = [];
      }
// Interviews 
      if (this.path == 'interviews' && Object.keys(this.route.snapshot.params).length == 0 && window['_current_user'].access > 1 ? 'hidden' : '') {
        this.http.getRequest(this.path+'/list?').subscribe((res: any) => {
          this.list=[];
            if (res.results.length){
              this.candidatesData=res;
              this.countPaging(res.total_pages);
              res.results.forEach(element => {
                this.list.push({
                  "id": element.id,
                  "schedule_time": element.schedule_time,
                  "non_scheduled": element.schedule_time? false : true,
                  "status": element.status,
                  "interview": element.interview,
                  "candidate": {
                    "name": element.candidate.name,
                    "email": element.candidate.email,
                    "position": {
                      "name": element.candidate.position ? element.candidate.position.name : null
                    }
                  },
                  "interviewer": { "username": element.interviewer.username }
                })
              });
        }
          table = this.initializeTable();
          this.registerEvents(table)
          $('i.create-item').insertAfter('#list-view_length').show();
          this.dateFilter=true;
          this.spinner.hide()
        })
      }
      else if(this.path == 'interviews' && window['_current_user'].access === 1 ? 'hidden' : '') {
        this.spinner.show()
        table = this.initializeTable2();
        this.registerEvents(table)
        $('i.create-item').insertAfter('#list-view_length').show();
        this.spinner.hide()
        if(this.path == 'interviews'){
          this.dateFilter=true;
        }else{
            this.dateFilter=false;
        }
      }
      else {
        table = this.initializeTable();
        this.registerEvents(table)
        $('i.create-item').insertAfter('#list-view_length').show();
        this.spinner.hide()
        if(this.path == 'interviews'){
          this.dateFilter=true;
        }else{
            this.dateFilter=false;
        }
      }
    });
    $('#list-view').on('click', 'tbody tr', function () {
      let row = table.row(this).data();
      self.router.navigate([`/${self.path}/edit/${row.id}`]);
    });
  }
public onDateFilterChange(event){
  if(event[0]==null && event[1]==null){
    this.ngOnInit();
  }else{
    this.spinner.show()
    this.http.getRequest(this.path + '/list?all' + this.filters).subscribe((res: any) => {
    this.listFilter=res.results;
    let table: any;
    this.list=[];
    let from=this.dateRange[0].getTime();
    let to=this.dateRange[1].getTime();
    this.listFilter.filter(d => {
      var res = d.schedule_time.split(" ");
      var set=res[0].split("/");
      var time = new Date(set[2]+'/'+set[1]+'/'+set[0]).getTime();
       if(from < time && time < to){
          this.list.push({
            "id": d.id,
            "schedule_time": d.schedule_time,
            "non_scheduled": d.schedule_time? false : true,
            "status": d.status,
            "interview": d.interview,
            "candidate": {
              "name": d.candidate.name,
              "email": d.candidate.email,
              "position": {
                "name": d.candidate.position ? d.candidate.position.name : null
              }
            },
            "interviewer": { "username": d.interviewer.username }
          })
       }
     });
     table = this.initializeTable();
     this.registerEvents(table)
     $('i.create-item').insertAfter('#list-view_length').show();
     this.dateFilter=true;
     this.spinner.hide()
  });
  }
  }
  public onClickClear(){
    this.dateRange='';
    this.ngOnInit();
  }
  // Reuseable Functions 
  public previousPage(){
    if(this.candidatesData.previous){
    let table: any;
    const regex = /(\?|\&)([^=]+)\=([^&]+)/g;
    const found = this.candidatesData.previous.match(regex);
    if(found){
      this.http.getRequest(this.path + '/list'+found[0]).subscribe((res: any) => {
        this.candidatesData=res;
        if (res.results.length){
          this.list = res.results;
        }
        table = this.initializeTable();
        this.registerEvents(table)
        $('i.create-item').insertAfter('#list-view_length').show();
        this.spinner.hide()
        if(this.path == 'interviews'){
          this.dateFilter=true;
        }else{
            this.dateFilter=false;
        }
      });
    }else{
      this.ngOnInit();
    }
  }
  }
  public nextPage():void{
    if(this.candidatesData.next){
      let table: any;
      const regex = /(\?|\&)([^=]+)\=([^&]+)/g;
      const found = this.candidatesData.next.match(regex);
      if(found){
        this.http.getRequest(this.path + '/list'+found[0]).subscribe((res: any) => {
          this.candidatesData=res;
          if (res.results.length){
            this.list = res.results;
          }
          table = this.initializeTable();
          this.registerEvents(table)
          $('i.create-item').insertAfter('#list-view_length').show();
          this.spinner.hide()
          if(this.path == 'interviews'){
            this.dateFilter=true;
          }else{
            this.dateFilter=false;
          }
        });
      }else{
        this.ngOnInit();
      }
    }
  }
  public searchfilter(){
    // search=azhar
      let table: any;
        this.http.getRequest(this.path + '/list?search='+this.search).subscribe((res: any) => {
          this.candidatesData=res;
          if (res.results.length){
            this.list = res.results;
            this.countPaging(res.total_pages);
          }
          table = this.initializeTable();
          this.registerEvents(table)
          $('i.create-item').insertAfter('#list-view_length').show();
          this.spinner.hide()
          if(this.path == 'interviews'){
            this.dateFilter=true;
          }else{
              this.dateFilter=false;
          }
        });
  }
  public pageSize(size){
        // search=azhar
        let table: any;
        this.http.getRequest(this.path + '/list?page_size='+size).subscribe((res: any) => {
          this.candidatesData=res;
          if (res.results.length){
            this.list = res.results;
            this.countPaging(res.total_pages);
          }
          table = this.initializeTable(size);
          this.registerEvents(table)
          $('i.create-item').insertAfter('#list-view_length').show();
          this.spinner.hide()
          if(this.path == 'interviews'){
            this.dateFilter=true;
          }else{
              this.dateFilter=false;
          }
        });
  }
  public pagination(index){
    let table: any;
      this.http.getRequest(this.path + '/list?page='+index).subscribe((res: any) => {
        this.candidatesData=res;
        if (res.results.length){
          this.list = res.results;
          this.countPaging(res.total_pages);
        }
        table = this.initializeTable();
        this.registerEvents(table)
        $('i.create-item').insertAfter('#list-view_length').show();
        this.spinner.hide()
        if(this.path == 'interviews'){
          this.dateFilter=true;
        }else{
            this.dateFilter=false;
        }
      });
  }
  public countPaging(page){
    this.paginationSet=page;
    this.paginationCount=[];
    for (let index = 0; index < this.paginationSet; index++) {
      this.paginationCount.push(index);
    }
  }
}

