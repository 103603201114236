<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<app-layout>
    <div class="d-flex justify-content-set">
        <div class="form-group w-100">
            <label for="exampleInputEmail1">Search</label>
            <input type="search" class="form-control search-width" [(ngModel)]="search" (ngModelChange)="searchfilter()">
        </div>
        <i *ngIf="user_access > 1" routerLink="{{'/'+path+'/create'}}" class="fa fa-plus fa-lg add-icon"></i>
    </div>
    <div *ngIf="dateFilter">
        <div class="set-potion-datepicker">
            <div class="form-group">
                <input placeholder="DD/MM/YYYY HH:MM" [selectMode]="'range'" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" [disabled]="user_access < 2" class="form-control" id="schedule_time" required name="schedule_time" [(ngModel)]="dateRange" (ngModelChange)='onDateFilterChange($event)'
                    readonly>
                <owl-date-time #dt1></owl-date-time>
            </div>
            <button type="button" (click)="onClickClear()" class="clear-buttton btn btn-light border border-dark float-right mr-2">Clear</button>
        </div>
    </div>
    <div class="main border rounded">
        <table id="list-view" class="{{'table table-striped table-bordered '+path}}">
            <thead>
                <tr>
                    <!-- <th *ngFor="let head of columns">{{head.title}}</th> -->
                </tr>
            </thead>
            <tfoot>
                <tr></tr>
            </tfoot>
        </table>
        <div class="d-flex set-pagination">
            <button (click)="previousPage()" class="btn btn-default list-style-set-pagination-button" *ngIf="paginationCount">
                <span id="previous"><a class="paginate_button current" aria-controls="list-view" data-dt-idx="1" tabindex="0">&lt;&lt;</a></span>
            </button>
            <ul class="list-style-set-pagination">
                <li *ngFor="let item of paginationCount"> <button (click)="pagination(item+1)" class="btn btn-default list-style-set-pagination-button">{{item+1}}</button></li>
            </ul>
            <button (click)="nextPage()" class="btn btn-default list-style-set-pagination-button" *ngIf="paginationCount">
                <span id="next"><a class="paginate_button current" aria-controls="list-view" data-dt-idx="1" tabindex="0">&gt;&gt;</a></span>
            </button>
        </div>
    </div>

</app-layout>